import {FC, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {MenuTestPage} from '../pages/MenuTestPage'
import CitywiseClosingReports from '../modules/report/citywise_closing_reports/citywise_closing_reports'

const PrivateRoutes = () => {
  const CustomerPage = lazy(() => import('../modules/customer/CustomerPage'))
  const MessagePage = lazy(() => import('../modules/messages/MessagePage'))
  const GeneralLedgerPage = lazy(
    () => import('../modules/report/generalledger/GeneralLedgerReport')
  )
  const CustomerLedgerPage = lazy(
    () => import('../modules/report/customerledger/CustomerLedgerPage')
  )
  const RecalculateLedgerPage = lazy(
    () => import('../modules/report/RecalculateLedgerReport')
  )
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Message Listing after success login */}
        <Route path='auth/*' element={<Navigate to='/message/list-messages' />} />
        {/* Pages */}
        <Route path='menu-test' element={<MenuTestPage />} />

        {/* Lazy Modules */}
        <Route
          path='customer/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />

        <Route
          path='message/*'
          element={
            <SuspensedView>
              <MessagePage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='report/supportticketarchive/*'
          element={
            <SuspensedView>
              <SupportticketarchiveReport />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='report/claimarchive/*'
          element={
            <SuspensedView>
              <ClaimarchivesPage />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='report/sentreceiveparts/*'
          element={
            <SuspensedView>
              <SentReceivepartsPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='report/generalledger/*'
          element={
            <SuspensedView>
              <GeneralLedgerPage />
            </SuspensedView>
          }
        />

<Route
          path='report/customerledger/*'
          element={
            <SuspensedView>
              <CustomerLedgerPage />
            </SuspensedView>
          }
        />
        <Route
          path='report/recalculate-ledger/*'
          element={
            <SuspensedView>
              <RecalculateLedgerPage />
            </SuspensedView>
          }
        />

        <Route
          path='report/citywise-closing-reports'
          element={<CitywiseClosingReports/>}
        />

        {/* <Route
          path='report/pendingtoreceivefromservicecenter/*'
          element={
            <SuspensedView>
              <PendingToReceivePartsPage />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='report/supportticketsanalysisbytickettype/*'
          element={
            <SuspensedView>
              <SupportticketanalysisReport />
            </SuspensedView>
          }
        /> */}

        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

import axios from 'axios'
import {configuration} from '../../../../configuration'

const API_URL = configuration.apiUrl

export const LOGIN_URL = `${API_URL}/auth/login`
export const LOGOUT_URL = `${API_URL}/auth/logout`
export const REGISTER_URL = `${API_URL}/register`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`

// Server should return AuthModel
export function login(mobile: string, code: string) {
  return axios.post<any>(LOGIN_URL, {mobile, code, type: 1}).then((response) => response.data)
}

export function logoutApi() {
  return axios.post(LOGOUT_URL).then((response) => response.data)
}

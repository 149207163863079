/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl, defaultAlerts, defaultLogs} from '../../../helpers'
import {
  getNotifications,
  updateNotification,
} from 'src/_metronic/layout/components/header/_requests'
import moment from 'moment'

const HeaderNotificationsMenu = ({showRedDot}:any) => {
  const [data, setData] = useState<any>([])

  const fetchData = async () => {
    try {
      await getNotifications()
        .then((data: any) => {
          if (data?.statusCode === 200 || data?.responseStatus === 200) {
            
            setData(data.responseData)
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.responseData?.msg) {
            console.log(error?.response?.data?.responseData?.msg)
          } else if (error?.response?.data?.responseMessage) {
            console.log(error?.response?.data?.responseMessage)
          } else {
            console.log(error)
          }
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  const handleNotificationClick = async (uuid: string) => {
    try {
      await updateNotification(uuid)
        .then((data: any) => {
          fetchData()
          showRedDot()
          if (data?.statusCode === 200 || data?.responseStatus === 200) {
            fetchData()
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.responseData?.msg) {
            console.log(error?.response?.data?.responseData?.msg)
          } else if (error?.response?.data?.responseMessage) {
            console.log(error?.response?.data?.responseMessage)
          } else {
            console.log(error)
          }
        })
    } catch (error) {
      console.error('Error updating notification:', error)
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
      style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>Notifications</h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'></li>
        </ul>
      </div>

      <div className='tab-content'>
        {/* <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'> */}
        <div className='scroll-y mh-325px my-5 px-8'>
        {data.length === 0 ? (
            <div className='text-center m-0 p-0 mt-12 pt-12 text-muted'>No notifications available.</div>
          ) : (data.map((alert: any, index: any) => (
            <div
              key={`alert${index}`}
              className='d-flex flex-stack py-2'
              onClick={() => handleNotificationClick(alert.uuid)}
            >
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                    {' '}
                    {alert?.entity_type === 1 ? (
                      <KTSVG path={`/media/transaction/approval.svg`} className={`svg-icon-2 `} />
                    ) : alert?.entity_type === 2 ? (
                      <KTSVG path={`/media/transaction/cancel.svg`} className={`svg-icon-2 `} />
                    ) : (
                      <KTSVG path={`/media/transaction/send.svg`} className={`svg-icon-2 `} />
                    )}
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a
                    href='#'
                    className={`fs-6 ${
                      alert?.read === 1
                        ? 'text-gray-400'
                        : alert?.entity_type === 1
                        ? 'text-success'
                        : alert?.entity_type === 2
                        ? 'text-danger'
                        : 'text-info-800'
                    } text-hover-primary fw-bolder`}
                  >
                    {alert.message_content}
                  </a>
                  {/* <div className='text-gray-400 fs-7'>{alert.message_content}</div> */}
                </div>
              </div>
              <span className='badge badge-light fs-8'>{moment(alert.created_at).fromNow()}</span>
            </div>
          )))}
        </div>
      </div>
    </div>
  )
}

export default HeaderNotificationsMenu

import { authAction } from './types'

export const setAuth = (token, data) => {
  return {
    type: authAction.SET_AUTH,
    payload: {
      token: token,
      isLoggedIn: true,
      data,
    },
  }
}

export const removeAuth = () => {
  return {
    type: authAction.REMOVE_AUTH,
    token: null,
    isLoggedIn: false,
    data: null,
  }
}

import { commonListAction } from "./types";

export const setDropdowmBranchList = (payload) => {
    return {
        type: commonListAction.SET_DROPDOWN_BRANCH_LIST,
        payload
    };
}

export const setCityList = (payload) => {
    return {
        type: commonListAction.SET_CITY_LIST,
        payload
    };
}  
import {Link} from 'react-router-dom'
import {useLayout} from '../../core'
import {constants} from '../../../../utils/const'

const SidebarLogo = () => {
  const {config} = useLayout()
  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
      <Link to='message/list-messages'>{constants.PROJECT_NAME}</Link>
    </div>
  )
}

export {SidebarLogo}

import {DateTime} from 'luxon' // helper: https://moment.github.io/luxon/demo/global.html

export const checkDisplayValue = (value) => {
  if (!value) {
    return '-'
  } else if (value == '') {
    return '-'
  } else {
    return value
  }
}

export function checkEmptyValue(value) {
  if (value === 0) {
    return true
  } else if (!value || value == '') {
    return false
  } else {
    return true
  }
}

export const formatDate = (date, format) => {
  return DateTime.fromISO(date).toFormat(format)
}

export const capitalizeFirstLetter = (string) => {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getFormatedAddress = (data) => {
  let address = ''
  if (checkEmptyValue(data?.addressline1)) {
    address += capitalizeFirstLetter(data?.addressline1)
  }
  if (checkEmptyValue(data?.addressline2)) {
    if (checkEmptyValue(data?.addressline1)) {
      address += `, ${capitalizeFirstLetter(data?.addressline2)}`
    } else {
      address += capitalizeFirstLetter(data?.addressline2)
    }
  }
  if (checkEmptyValue(data?.cityInfo?.name)) {
    if (checkEmptyValue(data?.addressline2)) {
      address += `, ${capitalizeFirstLetter(data?.cityInfo?.name)}`
    } else {
      address += capitalizeFirstLetter(data?.cityInfo?.name)
    }
  }
  if (checkEmptyValue(data?.stateInfo?.name)) {
    if (checkEmptyValue(data?.cityInfo?.name)) {
      address += `, ${capitalizeFirstLetter(data?.stateInfo?.name)}`
    } else {
      address += capitalizeFirstLetter(data?.stateInfo?.name)
    }
  }
  if (checkEmptyValue(data?.countryInfo?.name)) {
    if (checkEmptyValue(data?.stateInfo?.name)) {
      address += `, ${capitalizeFirstLetter(data?.countryInfo?.name)}`
    } else {
      address += capitalizeFirstLetter(data?.countryInfo?.name)
    }
  }
  if (checkEmptyValue(data?.pincodeInfo?.name)) {
    if (checkEmptyValue(data?.countryInfo?.name)) {
      address += `, ${data?.pincodeInfo?.name}`
    } else {
      address += ` ${data?.pincodeInfo?.name}`
    }
  }
  return checkDisplayValue(address)
}

import axios from "axios";
import { useEffect, useState } from "react";
import { configuration } from "src/configuration";
import { checkDisplayValue } from "src/utils";

const CitywiseClosingTable = ({data}:any) => {

    const [creditData,setCreditData] = useState<any>([])
    const [debitData,setDebitData] = useState<any>([])

    const [creditLoading,setCreditLoading] = useState<any>(true)
    const [debitLoading,setDebitLoading] = useState<any>(true)

    const [creditOrder,setCreditOrder] = useState<any>("asc")
    const [creditSort,setCreditSort] = useState<any>("customer_code")

    const [debitOrder,setDebitOrder] = useState<any>("asc")
    const [debitSort,setDebitSort] = useState<any>("customer_code")

    const getCreditReportData = async () => {
        try{
            setCreditLoading(true)
            let creditListData = await axios.get(`${configuration.apiUrl}/reports/citywise-closing/list/?city_id=${data.uuid}&sort=${creditSort}&order=${creditOrder}&dr_cr=1`)
            creditListData = creditListData?.data?.responseData?.data ?? []
            setCreditData(creditListData)
            setCreditLoading(false)
        }
        catch(err){
            setCreditLoading(false)
            console.error("err",err)
        }
    }

    const getDebitReportData = async () => {
        try{
            setDebitLoading(true)
            let debitListData = await axios.get(`${configuration.apiUrl}/reports/citywise-closing/list/?city_id=${data.uuid}&sort=${debitSort}&order=${debitOrder}&dr_cr=0`)
            debitListData = debitListData?.data?.responseData?.data ?? []
            setDebitData(debitListData)
            setDebitLoading(false)
        }
        catch(err){
            setDebitLoading(false)
            console.error("err",err)
        }
    }

    useEffect(()=>{
        getDebitReportData()
        getCreditReportData()
    },[data.uuid])

    useEffect(()=>{
        getDebitReportData()
    },[debitOrder])

    useEffect(()=>{
        getCreditReportData()
    },[creditOrder])

    return (
        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header border-0 pt-6">
                                <h2>Debit for {data?.city_name}</h2>
                                <div className="card-toolbar">
                                    <div className="d-flex justify-content-end" data-kt-sentreceivepart-table-toolbar="base"></div>
                                </div>
                            </div>
                            <div className="card-body py-4">
                                <div className="table-responsive">
                                    <table id="kt_table_general_report_debit" className="table align-middle table-row-dashed sentreceiveparts-report fs-6 gy-5 table-striped dataTable no-footer" role="table">
                                        <thead>
                                            <tr className="text-start text-gray-700 fw-bolder fs-6 text-uppercase gs-0">
                                                <th colSpan={1} role="columnheader" className="w-10px pe-2">
                                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3"></div>
                                                </th>
                                                <th colSpan={1} role="columnheader" className={`min-w-125px table-sort-${debitOrder}`} style={{ cursor: "pointer" }}
                                                  onClick={()=>{
                                                    if(debitOrder === "asc"){
                                                        setDebitOrder("desc")
                                                    }else{
                                                        setDebitOrder("asc")
                                                    }
                                                  }}
                                                >Code</th>
                                                <th colSpan={1} role="columnheader" className="min-w-125px" style={{ cursor: "pointer" }}>Customer Name</th>
                                                <th colSpan={1} role="columnheader" className="min-w-125px" style={{ cursor: "pointer" }}>Point</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-gray-600" role="rowgroup">
                                            {debitLoading ? 
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="d-flex text-center w-100 align-content-center justify-content-center">Loading...</div>
                                                    </td>
                                                </tr>
                                            : debitData.length > 0 ? (debitData.map((x:any,i:any)=>{
                                                return <tr>
                                                    <td></td>
                                                    <td>
                                                        {checkDisplayValue(x.code)}
                                                    </td>
                                                    <td>
                                                        {checkDisplayValue(x.branchname)}
                                                    </td>
                                                    <td>
                                                        {checkDisplayValue(x.point)}
                                                    </td>
                                                </tr>
                                            })) : 
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="d-flex text-center w-100 align-content-center justify-content-center">No matching records found</div>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header border-0 pt-6">
                                <h2>Credit for {data?.city_name}</h2>
                                <div className="card-toolbar">
                                    <div className="d-flex justify-content-end" data-kt-sentreceivepart-table-toolbar="base"></div>
                                </div>
                            </div>
                            <div className="card-body py-4">
                                <div className="table-responsive">
                                    <table id="kt_table_general_report_credit" className="table align-middle table-row-dashed sentreceiveparts-report fs-6 gy-5 table-striped dataTable no-footer" role="table">
                                        <thead>
                                            <tr className="text-start text-gray-700 fw-bolder fs-6 text-uppercase gs-0">
                                                <th colSpan={1} role="columnheader" className="w-10px pe-2">
                                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3"></div>
                                                </th>
                                                <th colSpan={1} role="columnheader" className={`min-w-125px table-sort-${creditOrder}`} style={{ cursor: "pointer" }}
                                                  onClick={()=>{
                                                    if(creditOrder === "asc"){
                                                        setCreditOrder("desc")
                                                    }else{
                                                        setCreditOrder("asc")
                                                    }
                                                  }}
                                                >Code</th>
                                                <th colSpan={1} role="columnheader" className="min-w-125px" style={{ cursor: "pointer" }}>Customer Name</th>
                                                <th colSpan={1} role="columnheader" className="min-w-125px" style={{ cursor: "pointer" }}>Point</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-gray-600" role="rowgroup">
                                            {creditLoading ? 
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="d-flex text-center w-100 align-content-center justify-content-center">Loading...</div>
                                                    </td>
                                                </tr>
                                            : creditData.length > 0 ? (creditData.map((x:any,i:any)=>{
                                                return <tr>
                                                    <td></td>
                                                    <td>
                                                        {checkDisplayValue(x.code)}
                                                    </td>
                                                    <td>
                                                        {checkDisplayValue(x.branchname)}
                                                    </td>
                                                    <td>
                                                        {checkDisplayValue(x.point)}
                                                    </td>
                                                </tr>
                                            })) : 
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="d-flex text-center w-100 align-content-center justify-content-center">No matching records found</div>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CitywiseClosingTable;

type TransactionStatusType = {
  [key: number]: string
  TRANSACTION_STATUS_PENDING: number
  TRANSACTION_STATUS_FORWARD: number
  TRANSACTION_STATUS_REJECT: number
  TRANSACTION_STATUS_DELETE: number
  TRANSACTION_STATUS_DELETE_CONFIRM: number
  TRANSACTION_STATUS_ONHOLD: number
  TRANSACTION_STATUS_HO_APPROVED: number
  TRANSACTION_STATUS_HO_REJECT: number
}

type MessageStatusType = {
  [key: number]: string
  MESSAGE_STATUS_DELIVERED: number
  MESSAGE_STATUS_READ: number
  MESSAGE_STATUS_CALL: number
  MESSAGE_STATUS_VERIFIED: number
  MESSAGE_STATUS_VERIFIED_FAILED: number
}

export const constants = {
  PROJECT_NAME: process.env.REACT_APP_TITLE,
  COMMISSION_API: process.env.REACT_APP_COMMISSION_API || `http://192.168.1.24:3002/getcommission`,

  TRANSACTION_STATUS: {
    0: 'Pending',
    1: 'Forward',
    2: 'Reject',
    3: 'Delete',
    4: 'Delete Confirm',
    5: 'On Hold',
    6: 'HO Approved',
    7: 'HO Rejected',
    TRANSACTION_STATUS_PENDING: 0,
    TRANSACTION_STATUS_FORWARD: 1,
    TRANSACTION_STATUS_REJECT: 2,
    TRANSACTION_STATUS_DELETE: 3,
    TRANSACTION_STATUS_DELETE_CONFIRM: 4,
    TRANSACTION_STATUS_ONHOLD: 5,
    TRANSACTION_STATUS_HO_APPROVED: 6,
    TRANSACTION_STATUS_HO_REJECT: 7,
  } as TransactionStatusType,

  TRANSACTION_STATUS_DROPDOWN: [
    { label: 'Pending', value: 0 },
    { label: 'Forward', value: 1 },
    { label: 'Reject', value: 2 },
    { label: 'Delete', value: 3 },
    { label: 'On Hold', value: 5 },
    { label: 'Ho Approved', value: 6 },
    { label: 'Ho Rejected', value: 7 },
  ],

  MESSAGE_STATUS: {
    0: 'Delivered',
    1: 'Read',
    2: 'Call',
    3: "Verified",
    4: "Verified Failed",
    MESSAGE_STATUS_DELIVERED: 0,
    MESSAGE_STATUS_READ: 1,
    MESSAGE_STATUS_CALL: 2,
    MESSAGE_STATUS_VERIFIED: 3,
    MESSAGE_STATUS_VERIFIED_FAILED: 4,
  } as MessageStatusType,

  MESSAGE_STATUS_DROPDOWN: [
    { label: 'Delivered', value: 0 },
    { label: 'Read', value: 1 },
    { label: 'Call', value: 2 },
    { label: 'Verified', value: 3 },
    { label: 'Verified Failed', value: 4 }
  ],

  JV_DROPDOWN: [
    { label: 'YES', value: 1 },
    { label: 'NO', value: 0 },
  ],

  DATE_FORMAT: {
    YYYY_MM_DD_HH_MM_SS: 'yyyy-MM-dd hh:mm:ss',
    DD_MM_YYYY_HH_MM_A: 'dd-MM-yyyy hh:mm a',
  },
}

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { configuration } from 'src/configuration';
import { checkDisplayValue } from 'src/utils';
import CitywiseClosingTable from './citywise_closing_table';

const CitywiseClosingReports = () => {
    const [cityData,setCityData] = useState<any>([])
    const [cityLoading,setCityLoading] = useState<any>(true)
    const [selectData,setSelectData] = useState<any>([])
    const [selectAll,setSelectAll] = useState<any>(false)
    const getCity = async () => {
        try{
            let cityList = await axios.get(`${configuration.apiUrl}/reports/citywise-closing/get-cities`)
            setCityData(cityList?.data?.responseData ?? [])
           
            setCityLoading(false)
        }
        catch(err){
            setCityLoading(false)
            console.error("err",err)
        }
    }

    useEffect(()=>{
        getCity()
    },[])

    const showCheckBox = (id:any) => {
        if(id === "all"){
            let data = cityData.find((x:any)=>!x.check) as any
            return data ? false : true
        }
        else{
            let data = cityData.find((x:any)=>x.uuid === id) as any
            return data?.check ? true : false
        }
    }

    const showReport = () => {
        if(selectAll){
            setSelectData(cityData)
        }
        else{
            let data = cityData.filter((x:any)=>x.check === true) as any
            setSelectData(data)
        }
    }

    return (
        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-fluid">
                <div className="row my-5">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header border-0 pt-6">
                            <h1> Citywise Closing Balance</h1>
                                <div className="card-toolbar">
                                    <div className="d-flex justify-content-end" data-kt-sentreceivepart-table-toolbar="base"></div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center'>
                                <div className='col-md-5'>
                                    <div className="card-body py-4 filter-table">
                                        <div className="table-responsive">
                                            <table id="kt_table_general_report_debit" className="table align-middle table-row-dashed sentreceiveparts-report fs-6 gy-5 table-striped dataTable no-footer" role="table">
                                                <thead>
                                                    <tr className="text-start text-gray-700 fw-bolder fs-6 text-uppercase gs-0">
                                                        <th colSpan={1} role="columnheader" className="w-10px pe-2">
                                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3"></div>
                                                        </th>
                                                        <th colSpan={2} role="columnheader" className="min-w-125px" style={{ cursor: "pointer" }}>Cities Name</th>
                                                        {/* <th colSpan={1} role="columnheader" className="min-w-125px" style={{ cursor: "pointer" }}></th> */}
                                                        <th colSpan={1} role="columnheader" className="min-w-125px d-flex justify-content-end" style={{ cursor: "pointer" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" checked={showCheckBox("all")} id="flexCheckDefaulta" onClick={()=>{ 
                                                                    let updatedCityData = cityData.map((city:any) => ({
                                                                        ...city,
                                                                        check: !showCheckBox("all")
                                                                    }));
                                                                    setCityData(updatedCityData);
                                                                }}/>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-600 text-center" role="rowgroup">
                                                    {cityLoading ? 
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <div className="d-flex text-center w-100 align-content-center justify-content-center">Loading...</div>
                                                            </td>
                                                        </tr>
                                                    : cityData.length > 0 ? (cityData.map((x:any,i:any)=>{
                                                        return <tr>
                                                            <td></td>
                                                            <td>
                                                                {checkDisplayValue(x.city_name)}
                                                                {showCheckBox(x.uuid)}
                                                            </td>
                                                            <td>
                                                                {checkDisplayValue(x.total_amount)}
                                                            </td>
                                                            <td className='d-flex justify-content-end'>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" checked={showCheckBox(x.uuid)} id="flexCheckDefault"
                                                                    onClick={()=>{
                                                                        let cData = JSON.parse(JSON.stringify(cityData))
                                                                        if(x?.check){
                                                                            cData[i].check = false
                                                                            setCityData(cData)
                                                                        }
                                                                        else{
                                                                            cData[i].check = true
                                                                            setCityData(cData)
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })) : 
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className="d-flex text-center w-100 align-content-center justify-content-center">No matching records found</div>
                                                        </td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card-body py-4 mt-3 mb-3">    
                                        <button onClick={() => {
                                           showReport() 
                                        }} className='btn btn-primary' style={{width:'100%'}}>
                                            Find
                                        </button>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
                {selectData.map((x:any)=>{
                    return <CitywiseClosingTable data={x}/>
                })}
            </div>
        </div>
    );
}

export default CitywiseClosingReports;

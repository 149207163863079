/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {logoutApi} from '../../../../app/modules/auth/core/_requests'
import {setMessage} from '../../../../store/alertMessage/actions'
import {removeAuth} from '../../../../store/authDetail/actions'

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logout = async () => {
    try {
      await logoutApi()
      dispatch(setMessage('success', 'Logout Successfully'))
      localStorage.clear()
      dispatch(removeAuth())
      navigate('/login')
    } catch (err) {
      dispatch(setMessage('success', 'Logout Successfully'))
      localStorage.clear()
      dispatch(removeAuth())
      navigate('/login')
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      style={{maxHeight: '50px', minHeight: '50px'}}
    >
      <div className='menu-item px-5'>
        <a
          onClick={(e) => {
            e.preventDefault()
            logout()
          }}
          className='menu-link px-5'
        >
          Logout
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

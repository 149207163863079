import {Suspense, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Outlet} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {setMessage, removeMessage} from '../store/alertMessage/actions'
import {AuthInit} from './modules/auth'
import {configuration} from '../configuration.js'
import {setCityList, setDropdowmBranchList} from '../store/commonlist/actions'
import axios from 'axios'

const App = () => {
  const API_URL = configuration.apiUrl
  const CITY_URL = `${API_URL}/cities`
  const DROPDOWN_BRANCHES_URL = `${API_URL}/branches/getDropDownBranches`
  const alertMessage = useSelector((state: any) => state.alertMessage.message)
  const alertStatus = useSelector((state: any) => state.alertMessage.status)
  const alertTime = useSelector((state: any) => state.alertMessage.time)
  const isLoggedIn = useSelector((state: any) => state.authDetail.isLoggedIn)

  const dispatch = useDispatch()

  const getBranches = async () => {
    await axios
      .get(`${DROPDOWN_BRANCHES_URL}?sort=branch_name&order=asc`)
      .then(({ data }: any) => {
        dispatch(
          setDropdowmBranchList(
            data?.responseData?.map((x: any) => {
              return {
                label: x.branch_name,
                value: x.uuid,
                ...x,
              }
            })
          )
        )
      })
      .catch((error) => {
        dispatch(setMessage('error', error.message))
      })
  }
  const getCity = async () => {
    await axios
      .get(`${CITY_URL}?sort=name&order=ASC`)
      .then(({data}: any) => {
        dispatch(
          setCityList(
            data?.responseData?.map((x: any) => {
              return {
                label: x.city_name,
                value: x.uuid,
                ...x,
              }
            })
          )
        )
      })
      .catch((error) => {
        dispatch(setMessage('error', error.message))
      })
  }

  useEffect(() => {
    if (isLoggedIn) {
      getBranches()
      getCity()
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (alertMessage || alertMessage !== '') {
      let message = alertMessage
      let status = alertStatus
      let time = alertTime
      dispatch(removeMessage())
      if (status === 'success') {
        toast.success(message, {
          toastId: message,
          position: 'top-center',
          autoClose: time * 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      } else if (status === 'error') {
        toast.error(message, {
          toastId: message,
          position: 'top-center',
          autoClose: time * 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    }
  }, [alertMessage])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ToastContainer limit={1} />
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}

/* eslint-disable jsx-a11y/anchor-is-valid */
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {setMessage} from '../../../../store/alertMessage/actions'
import {setAuth} from '../../../../store/authDetail/actions'
import {login} from '../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
const loginSchema = Yup.object().shape({
  mobile: Yup.string()
  .test('length', `Enter 10 digit mobile number`, (val: any) => val && val.length === 10)
  .test('format', `Invalid mobile number format`, (val: any) => /^[6789]\d{9}$/.test(val))
  .required(`Mobile number is required`),  
  code: Yup.string().required('Code is required'),
})

const initialValues = {
  mobile: '',
  code: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [visialbe, setVisiable] = useState(false)
  const intl = useIntl()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await login(values.mobile, values.code)
          .then((data: any) => {
            if (data.responseStatus === 200) {
              if (data?.responseData?.token) {
                dispatch(setAuth(data?.responseData?.token, data?.responseData?.data))
                dispatch(setMessage('success', data.responseMessage))
              } else {
                dispatch(setMessage('error', 'Something Went Wrong'))
              }
              setLoading(false)
            } else {
              dispatch(setMessage('error', data.responseMessage))
              setLoading(false)
            }
          })
          .catch((error) => {
            if (error?.response?.data?.responseData?.msg) {
              dispatch(setMessage('error', error?.response?.data?.responseData?.msg))
            } else if (error?.response?.data?.responseMessage) {
              dispatch(setMessage('error', error?.response?.data?.responseMessage))
            } else {
              dispatch(setMessage('error', error?.message))
            }
            setLoading(false)
          })
      } catch (error: any) {
        dispatch(setMessage('error', error.message ? error.message : 'Something Went Wrong'))
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className=' fw-bolder mb-3 '><FormattedMessage id='LOGIN' /></h1> 
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='MOBILE' /></label>
        <input
          placeholder={intl.formatMessage({id: 'PLACEHOLDER.MOBILE'})}
          {...formik.getFieldProps('mobile')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.mobile && formik.errors.mobile},
            {
              'is-valid': formik.touched.mobile && !formik.errors.mobile,
            }
          )}
          type='phone'
          name='mobile'
          autoComplete='off'
          maxLength={10}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const key = e.key;
            const isNumeric = /^[0-9]$/.test(key);
            const isSpecialKey =
              key === 'Backspace' ||
              key === 'Delete' ||
              key === 'ArrowLeft' ||
              key === 'ArrowRight' ||
              key === 'ArrowUp' ||
              key === 'ArrowDown' ||
              (e.ctrlKey && (key === 'a' || key === 'c' || key === 'v' ));  // Handle Ctrl + A
                    if (!isNumeric && !isSpecialKey && key !== 'Tab') { // Exclude 'Tab' key
                      e.preventDefault();
                    }
                
                    if (key === 'Tab' || !isNumeric && !isSpecialKey) {
                     
                      const nextInput = e.currentTarget.nextSibling as HTMLInputElement | null;
                      if (nextInput) {
                        nextInput.focus();
                        e.preventDefault(); // Prevent default Tab behavior (e.g., moving to the next focusable element)
                      }
                    }
                  }}
                 
        />
        {formik.touched.mobile && formik.errors.mobile && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.mobile}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='OTP' /></label>
        <div className='input-group fv-col mb-3'>
          <input
            placeholder={intl.formatMessage({id: 'PLACEHOLDER.OTP'})}
            type={`${visialbe === true ? 'text' : 'password'}`}
            autoComplete='off'
            {...formik.getFieldProps('code')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.code && formik.errors.code,
              },
              {
                'is-valid': formik.touched.code && !formik.errors.code,
              }
            )}
          />
          <div className='input-group-append'>
            {visialbe === true ? (
              <span className='input-group-text' onClick={() => setVisiable(false)}>
                <VisibilityIcon />
              </span>
            ) : (
              <span className='input-group-text' onClick={() => setVisiable(true)}>
                <VisibilityOffIcon />
              </span>
            )}
          </div>
          {/* <span onClick={() => setVisiable(true)}>
            <VisibilityOffIcon />
          </span>

          <span onClick={() => setVisiable(false)}>
            <VisibilityIcon />
          </span> */}
          {/* <i className={`fa-sharp fa-solid fa-eye fs-5 text-primary`}></i> */}
        </div>
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.code}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn '
          disabled={formik.isSubmitting || !formik.isValid || loading}
        >
          {!loading && <span className='indicator-label'><FormattedMessage id='CONTINUE' /></span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
             <FormattedMessage id='LOGIN.PLEASE.WAIT' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}

/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
  // TODO - update routes as per requirement

  return (
    <>
      <SidebarMenuItem
        to='/customer/list-customer'
        title={intl.formatMessage({id: 'CUSTOMER'})}
        icon='/media/icons/duotune/general/gen049.svg'
      />

      <SidebarMenuItem
        to='/message/list-messages'
        title={intl.formatMessage({id: 'MESSAGES'})}
        icon='/media/icons/duotune/general/transaction.svg'
      />

      <SidebarMenuItemWithSub
        to='/report'
        title={intl.formatMessage({id: 'REPORTS'})}
        icon='/media/icons/duotune/general/gen005.svg'
        fontIcon='bi-person'
      >
         <SidebarMenuItem
          to='/report/customerledger/list-customerledger'
          title={intl.formatMessage({id: 'CUSTOMER.LEDGER'})}
          hasBullet={true}
        />
        {/* <SidebarMenuItem
          to='/report/notification/list-notification'
          title='Notification'
          hasBullet={true}
        /> */}
        <SidebarMenuItem
          to='/report/generalledger/list-generalledger'
          title={intl.formatMessage({id: 'GENERAL.LEDGER'})}
          hasBullet={true}
        />

        {/* <SidebarMenuItem
          to='/report/claimarchive/ClaimarchiveReports'
          title='Transactions'
          hasBullet={true}
        /> */}

        {/* <SidebarMenuItem
          to='/report/claimarchive/ClaimarchiveReports'
          title='Deleted Transactions'
          hasBullet={true}
        /> */}

        <SidebarMenuItem to='/report/recalculate-ledger' title={intl.formatMessage({id: 'RECALCULATE'})} hasBullet={true} />

        <SidebarMenuItem
          to='/report/citywise-closing-reports'
          title={intl.formatMessage({id: 'CITYWISE.CLOSEING'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
